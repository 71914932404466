function validateFieldLength(field, model, next, maxLength = 50) {
  const value = model[field.key];
  return next(!value || value.length <= maxLength);
}

const vynePartnerFields = [
  {
    key: 'merchantId',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        data: '',
        placeholder: 'Merchant ID',
        icon: 'tag-outline',
      },
      wrapper: {
        properties: {
          label: 'Merchant ID',
        },
      },
    },
    validators: {
      length: {
        expression: (field, model, next) => validateFieldLength(field, model, next, 20),
        message: 'This Merchant ID must be no longer than 20 characters',
      },
    },
  },
  {
    key: 'destinationAccount',
    type: 'input-with-field',
    required: true,
    templateOptions: {
      properties: {
        type: 'text',
        placeholder: 'Destination Account ID',
        icon: 'bank-transfer-in',
      },
      wrapper: {
        properties: {
          label: 'Destination Account ID',
        },
      },
    },
    validators: {
      length: {
        expression: validateFieldLength,
        message: 'This name must be no longer than 50 characters',
      },
    },
  },
  {
    key: 'tokenKrn',
    type: 'input-with-field',
    required: false,
    templateOptions: {
      properties: {
        type: 'text',
        placeholder: 'Token KRN',
        icon: 'tag-outline',
      },
      wrapper: {
        properties: {
          label: 'Token KRN',
          message: 'Vyne has been deprecated, you must provide a valid Token KRN',
        },
      },
    },
    validators: {
      length: {
        expression: (field, model, next) => validateFieldLength(field, model, next, 100),
        message: 'This Token KRN must be no longer than 100 characters',
      },
    },
  },
  {
    key: 'uat',
    type: 'select-with-field',
    templateOptions: {
      options: [
        {
          text: 'UAT',
          value: 'UAT',
        },
        {
          text: 'Live',
          value: 'LIVE',
        },
      ],
      properties: {
        placeholder: 'Select an interface status',
      },
      wrapper: {
        properties: {
          addons: false,
          label: 'Interface Status',
        },
      },
    },
  },
  {
    key: 'countryCode',
    type: 'select-with-field',
    templateOptions: {
      options: [
        {
          text: 'GB',
          value: 'GB',
        },
      ],
      properties: {
        placeholder: 'Select the country code',
      },
      wrapper: {
        properties: {
          addons: false,
          label: 'Country code',
        },
      },
    },
  },
];

export default vynePartnerFields;
